// Styles:
import './style.scss';

import {scrollToElement} from '../../../js/global-functions';

let scrollIndicator = document.querySelector('.scroll-indicator');

if (scrollIndicator) {
    scrollIndicator.addEventListener('click', scrollDownSection);
}

function scrollDownSection(event) {
      
    let parent = event.target.closest('.main_site_header');
    let elemntOffset = parent.nextElementSibling.offsetTop - 100;
    let scrollFrame = window;

    if (scrollFrame) {
      scrollToElement(scrollFrame, elemntOffset, 'smooth');
    }
      
}
