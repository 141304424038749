// Styles:
import './style.scss';

const videoMods = [].slice.call(document.querySelectorAll('.video_mod'));

if (videoMods) {
	videoMods.forEach(video =>  {
        
        const play = video.querySelector('.video_mod--overlay-play');

        if (play) {
  
            play.addEventListener('click', (e) => {

                if ( e.target.parentNode.classList.contains('video_mod') ) {
                    e.target.parentNode.classList.add('active');
                }
		
            });
        }
        
	});
}