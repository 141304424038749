import './style.scss';
require('intersection-observer'); // polyfill for older browsers

require('./lazy-vids'); // polyfill for older browsers

document.addEventListener("DOMContentLoaded", function() {

	const supports = checkSupport();

    // var lazyImages = [].slice.call(document.querySelectorAll(".lazy"));
    let lazyImages = document.querySelectorAll(".lazy");

    if ('IntersectionObserver' in window) {

        const observerOptions = {
            root: null, // viewport
            rootMargin: "100px", // pixeloffset outside frame
            // threshold: 0.1 // when 50% of element visible
		};
		
		let isInteracting = false;
        let interactingCount = 0;

        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
				
				if (entry.isIntersecting) {

                    function buildImage() {
                        let lazyImageWrap = entry.target;
						let lazyImage = entry.target.querySelector('img');
	
						if (lazyImage) {
							if (supports.srcset && lazyImage.dataset.srcset) {
								lazyImage.srcset = lazyImage.dataset.srcset;
							} else {
								lazyImage.src = lazyImage.dataset.src;
							}
							lazyImageWrap.classList.remove('lazy');
							lazyImageWrap.classList.add('animate');
							lazyImageObserver.unobserve(lazyImageWrap);
							
							lazyImage.onload = function() {
								setTimeout(function(){
									lazyImageWrap.classList.add('loaded');
								}, 60);	
							}
							
						}
                        
                    }

                    if (!isInteracting) {
                        buildImage();
                    } else {
                        setTimeout(function(){
                            buildImage();
                        },
                        120 * interactingCount);
                    }

                    isInteracting = true; 
                    interactingCount = interactingCount + 1;

                    setTimeout(function(){
                        isInteracting = false; 
                        interactingCount = 0; 
                    }, 120); // reset every 120 milliseconds so we don't get a to big delay for later interactions 

                }

            });
        }, observerOptions);

		Array.prototype.forEach.call(lazyImages, function (lazyImage) {
			lazyImageObserver.observe(lazyImage);
		});

    } // Possibly fall back to a more compatible method here. But we are loading a Polyfill - so we do nothing


});

function checkSupport() {
	const supports = {
		srcset: false,
		currentSrc: false,
		sizes: false,
		picture: false
	};
  
	let img = new Image();

	if ("srcset" in img) {
		supports.srcset = true;
	}

	if ("currentSrc" in img) {
		supports.currentSrc = true;
	}

	if ("sizes" in img) {
		supports.sizes = true;
	}

	if ("HTMLPictureElement" in window) {
		supports.picture = true;
	}

	// console.log('Picture support: ' + supports.picture);
	// console.log('sizes support: ' + supports.sizes);
	// console.log('srcset support: ' + supports.srcset);
	// console.log('currentSrc support: ' + supports.currentSrc);

	return supports;
}
