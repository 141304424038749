// Styles:
import './style.scss';

const scrollableActionsA = [].slice.call(document.querySelectorAll('.scrollable-actions-a'));

if (scrollableActionsA) {

	scrollableActionsA.forEach(function(slider) {

		let paused = false;

		// scrollableActionsA.classList.remove('no-js');

		const list = slider.querySelector('.scrollable-actions-a-items');
		const slides = [].slice.call(list.querySelectorAll('.scrollable-actions-a--item'));

		const observerSettings = {
			root: slider,
			// rootMargin: '-10px',
			threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
		}

		let n=0; /* Total number of articles viewed */

		if ('IntersectionObserver' in window) {
			
			const callback = (slides, observer) => {
				// Array.prototype.forEach.call(slides, function (entry) {
				slides.forEach( function (entry) {

					if ( ! paused ) {

						entry.target.classList.remove('visible');
						entry.target.classList.remove('part');
						if (!entry.intersectionRatio > 0) {
							return;
						} 
						
						if ( entry.intersectionRatio > 0.1) {
							entry.target.classList.add('visible');
							entry.target.classList.add('part');
						}

						if ( entry.intersectionRatio > 0.9) {
							entry.target.classList.remove('part');
						}

						if (entry.intersectionRatio === 1) {
							entry.target.classList.remove('part');
							// _this.updateControls();
							return;
						}

					} else {
						console.log('paused');
					}
								
				});
			}

			const observer = new IntersectionObserver(callback, observerSettings);
			Array.prototype.forEach.call(slides, t => observer.observe(t));
		}

		const controls = slider.querySelector('.slider-controls');
		let prev = controls.querySelector('.previous');
		let next = controls.querySelector('.next');
		prev.disabled = false;

		function scrollIt(slideToShow) {
			// paused = true;
			let scrollPos = slideToShow.offsetLeft;
			list.scrollTo({
				left: scrollPos,
				behavior: 'smooth'
			});

			// paused = false;

			return scrollPos;
		}

		function showSlide(dir, slides) {

			const visible = [].slice.call(slider.querySelectorAll('.scrollable-actions-a .visible'));
			const slideCount = slides.length;
			const visibleCount = visible.length;

			if (dir === prev) {
				if (visibleCount > 1) {
					if (visible[0].classList.contains('part') ) {
						scrollIt(visible[0]);
					} else if (visible[0].previousElementSibling) {
						scrollIt(visible[0].previousElementSibling);
					}
				} else {
					if (slides.indexOf(visible[0]) !== 0) {
						scrollIt(visible[0].previousElementSibling);
					}
				}
			}

			if (dir === next) {
				const lastIndex = visibleCount - 1;
				if (visibleCount > 1) {
					if (visible[lastIndex].classList.contains('part') ) {
						scrollIt(visible[lastIndex]);
					} else if (visible[lastIndex].nextElementSibling) {
						scrollIt(visible[lastIndex].nextElementSibling);
					}
				} else {
					if (slides.indexOf(visible[lastIndex]) !== (slideCount - 1)) {
						scrollIt(visible[lastIndex].nextElementSibling);
					}
				}
			}

		}

		controls.addEventListener('click', function (e) {
			showSlide(e.target.closest('button'), slides);
		});

		// function disable() {
		// 	prev.disabled = list.scrollLeft < 1;
		// 	next.disabled = list.scrollLeft === list.scrollWidth - list.offsetWidth;
		// }

		// let debounced;
		// list.addEventListener('scroll', function () {
		// 	window.clearTimeout(debounced);
		// 	debounced = setTimeout(disable, 2000);
		// });
	});
}