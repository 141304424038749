// Polyfills
require('../modules/starterkit/polyfills/foreach');
require('../modules/starterkit/polyfills/classlist');
require('../modules/starterkit/polyfills/arrayincludes');
require('../modules/starterkit/polyfills/css-vars');

import smoothscroll from 'smoothscroll-polyfill';
// kick off the polyfill!
smoothscroll.polyfill();

// Detect user related things
require('../modules/starterkit/detect-things');

// Smooth scroll links
require('../modules/starterkit/smooth-scroll-links');

// Theme specific styles
import '../scss/style.scss'; // Basic Site styling

// Tweaks
import '../modules/starterkit/viewport-pixels';

// Site wide js
import '../modules/starterkit/lazyloading';

// Starterkit styles
import '../modules/starterkit/404';

import '../modules/theme/acf-layout-settings';

import '../modules/theme/text';
import '../modules/theme/header-b';
import '../modules/theme/site-header';
import '../modules/theme/site-footer';
import '../modules/theme/faq';
import '../modules/theme/faq-a';
import '../modules/theme/news-item';

import '../modules/theme/actions-b';
import '../modules/theme/image-a';
import '../modules/theme/pin-map';
import '../modules/theme/pins-popup';
import '../modules/theme/video';
import '../modules/theme/scrollable-news-items-a';
import '../modules/theme/text-4-columns';

import '../modules/theme/news';
import '../modules/theme/slider';
import '../modules/theme/quote';

import '../modules/theme/buttons-a';
import '../modules/theme/popup';
import '../modules/theme/brochures';

import '../modules/theme/google-map-locations';
