// Styles:
import './style.scss';

// import ScrollMagic from 'ScrollMagic';

// let controller = new ScrollMagic.Controller();

// var w = window.innerWidth;

// window.addEventListener('resize', function() {
// 	w = window.innerWidth;

// 	if( controller !== null && controller !== undefined ) {
// 		// completely destroy the controller
// 		controller = controller.destroy( true );
// 	}

// 	controller = new ScrollMagic.Controller();

// 	scrollMagicAnimations();
// });



// function scrollMagicAnimations() {
// 	// create a scene
// 	new ScrollMagic.Scene({
// 		duration: 0, // the scene should last for a scroll distance of 100px
// 		offset: 0 // start this scene after scrolling for 50px
// 	})
// 		.setPin('#site-header-nav--logo-icon') // pins the element for the the scene's duration
// 		.addTo(controller); // assign the scene to the controller
// 	};

// scrollMagicAnimations();



// js:
let html = document.querySelector('html');
let body = document.querySelector('body');
let mainContent = document.getElementById('main__content');
let mainNav = document.querySelector('.site-header');
let singleInfoToggle = document.querySelector('.portfolio-single-info-toggle');
var scrollDownBtn = [].slice.call(document.querySelectorAll('.scroll-down'));
var scrollToTopBtn = [].slice.call(document.querySelectorAll('.scroll-totop'));
let overlayNav = document.getElementById('overlay-nav');
let overlayNavToggle = document.getElementById('toggle-overlay-nav');
let ticking = false;

const scrollData = {
    scrollY: window.scrollY,
    lastScrollPos: null,
    direction: null,
}

window.addEventListener('scroll', (e)=> {
	if (window.scrollY > scrollData.scrollY) {
		scrollData.direction = 'down';
	} else if (window.scrollY < scrollData.scrollY) { 
		scrollData.direction = 'up';
	}
	scrollData.scrollY = window.scrollY;
	
	doOnAnimationFrame();
	// stickyNav();
});

if (scrollDownBtn) {
	scrollDownBtn.forEach(btn => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();
			let parentEl = false;
			let parent = e.target.dataset.parent;
			if ( parent ) {
				parentEl = document.querySelector(parent);
			}
			if (parentEl) {
				let parentH = parseInt(parentEl.getBoundingClientRect().height);
				let scrollOptions = {
					// left: leftInput.value,
					top: parentH,
					behavior: 'smooth' // 'smooth' : 'auto'
				}
				  window.scrollTo(scrollOptions);
			}
			
		});
	});
}

if (scrollToTopBtn) {
	scrollToTopBtn.forEach(btn => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();
			let scrollOptions = {
				top: 0,
				behavior: 'smooth' // 'smooth' : 'auto'
			}
			window.scrollTo(scrollOptions);
		});
	});
}

if (singleInfoToggle) {
	singleInfoToggle.addEventListener('click', (e) => {
		e.preventDefault();
		e.target.parentNode.classList.toggle('active');
	});
}

function openNav() {
    setTimeout(function() {
        // body.replaceClass('nav-opening', 'nav-open');
        body.classList.remove('nav-opening');
        body.classList.add('nav-open');
    }, 800);
}

function closeNav() {
    setTimeout(function () {
        // body.replaceClass('nav-closing', 'nav-closed hide_nav');
        body.classList.remove('nav-closing');
        body.classList.add('nav-closed ');
        body.classList.add('hide_nav');
    }, 800);
}

function positionNav() {
    setTimeout(function () {
        // body.replaceClass('nav-closed', 'nav-opening');
        body.classList.remove('nav-closed');
        body.classList.add('nav-opening');
        openNav();
    }, 30);
}

if (overlayNav && overlayNavToggle) {
    overlayNavToggle.addEventListener('click', function() {
        if(overlayNav.classList.contains('active')) {
        	overlayNavClose(scrollData.lastScrollPos);
        } else {
            overlayNavOpen();
        }
	});

	const ul = document.getElementById('overlay-nav-menu');
	const bg = document.getElementById('overlay-nav-bg');

	if (ul && bg) {
		const navItems = ul.querySelectorAll('li');
		if (navItems) {
			window.ACTIVE_NAV_BG = -1;
			navItems.forEach( (item, index) => {
				const bgUrlEl = item.querySelector('span.attr-data-nav-image');
				if ( bgUrlEl ) {
					item.addEventListener('mouseover', function onFirstHover() {
						window.USER_CAN_HOVER = true;
						window.removeEventListener('mouseover', onFirstHover, false);
						
						if ( window.ACTIVE_NAV_BG !== index) {
							// lets do the background change here.
							const bgUri = bgUrlEl.dataset.navImage;
							const img = bg.querySelector('img');

							img.src = bgUri;
							img.srcset = '';
						}

						window.ACTIVE_NAV_BG = index;

					}, false);
				}
			});
		}
	}
}

function overlayNavOpen() {
    let scrollPos = window.scrollY;
    scrollData.lastScrollPos = scrollPos;
	html.classList.add('overlay-nav-open');
	if (mainNav) {
		mainNav.classList.remove('sticky', 'animate-out', 'animate');
		mainNav.classList.add('overlay-nav-is-open');
	}

	// mainContent.style.display = scrollPos - 36; // remember last scroll position
	// console.log(scrollPos);
	// mainContent.style.transform = "translateY(-" + scrollPos + "px)"; // remember last scroll position
	// mainContent.style.marginTop = "-" + scrollPos + "px"; // remember last scroll position
	
	// html.style.transform = "translateY(-" + scrollPos + "px)"; // remember last scroll position
	// body.style.transform = "translateY(-" + scrollPos + "px)"; // remember last scroll position
	// mainContent.style.transform = "translateY(-" + scrollPos + "px)"; // remember last scroll position

    overlayNavToggle.classList.add('active');
    overlayNav.classList.add('active');
    overlayNav.classList.add('animate');
	overlayNav.classList.remove('animate-out');
}

function overlayNavClose(scrollPos) {
    // scrollData.scrollY = scrollPos; // fixes logo flikker scroll state after closing
	html.classList.remove('overlay-nav-open');
	if (mainNav) {
		mainNav.classList.remove('sticky', 'animate-out', 'animate');
		mainNav.classList.remove('overlay-nav-is-open');
	}

	// mainContent.style.removeProperty('transform');
	// mainContent.style.marginTop = ""; // remember last scroll position

    // html.scrollTop = scrollPos; // fixes last scroll position
    // body.scrollTop = scrollPos; // fixes last scroll position
    // mainContent.scrollTop = scrollPos; // fixes last scroll position

    overlayNavToggle.classList.remove('active');
    overlayNav.classList.remove('active');
	overlayNav.classList.add('animate-out');
	setTimeout(function() {
        overlayNav.classList.remove('type-form');
    	overlayNav.classList.remove('type-nav');
    }, 500);
}


let stickyNav = () => {
	if (scrollData.scrollY > window.innerHeight ) {
		body.classList.add('scrolltop-active');
	} else {
		body.classList.remove('scrolltop-active');
	}
}
if (mainNav) {
    stickyNav();
}

function doOnAnimationFrame(){

    // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
    if (!ticking) {

        window.requestAnimationFrame(function() {

            ticking = false;

        });
	}
	stickyNav();
    ticking = true;

}















/**
 * Contains some menu scripting to facilitate the switch between mobile and desktop version.
 */

( function () {
    if (window.innerWidth <= 1024) {

        let addBackButtons = function addBackButtons() {
            let subNavs = [].slice.call(mainNav.querySelectorAll('.menu-item-has-children'));

            let backButton = `<a class="back">Terug</a>`;
            let backToMain = `<a class="back_main">Terug naar het hoofdmenu</a>`;

            subNavs.forEach(sub => {
                sub.insertAdjacentHTML('afterbegin', backButton);            
                sub.insertAdjacentHTML('afterbegin', backToMain);            
            });
        };

        let mainNav = document.querySelector('.main-menu.mob-nav');

        if (mainNav) {

            addBackButtons();

            document.addEventListener('click', function (event) {
                if (event.target.matches('.menu-item-has-children')) {

                    if( event.target.classList.contains('active') ) {
                        disableChildSubs(event.target);
                    };

                    checkActiveChildSubs(event.target.parentNode.parentNode, event.target);
                    event.target.classList.toggle('active');
                    checkActiveSubs();

                } else if (event.target.matches('.menu-item:not(.menu-item-has-children) > a:not(.back):not(.back_main)')) {
                    overlayNavClose(scrollData.lastScrollPos);
                } else if (event.target.matches('.menu-item-has-children > a:not(.back):not(.back_main)')) {

                    if (! event.target.parentNode.classList.contains('active')) {
                        event.preventDefault();
                        event.target.parentNode.classList.toggle('active');
                        checkActiveSubs();
                        checkActiveChildSubs(event.target.parentNode.parentNode.parentNode, event.target);                   
                    }

                } else if (event.target.matches('a.back_main')) {

                    event.target.parentNode.classList.toggle('active');
                    checkActiveSubs();

                } else if (event.target.matches('a.back')) {

                    disableChildSubs(event.target.parentNode.parentNode.parentNode); // also remove dynamic back buttons
                    checkActiveSubs();

                } else {
                    return;
                }
            }, false);
        }
        let checkActiveSubs = function checkActiveSubs() {
            let activeNavs = [].slice.call(mainNav.querySelectorAll('.menu-item-has-children.active'));
            if (activeNavs.length && activeNavs.length >= 1) {
                mainNav.classList.add('sub_navs_active');
            } else {
                mainNav.classList.remove('sub_navs_active');
            };
        };
        let checkActiveChildSubs = function checkActiveChildSubs(element, target) {
            if( element.matches('.menu-item-has-children') ) {
                if (! target.classList.contains('active')) {
                    element.classList.add('child_sub_active');
                } else {
                    element.classList.remove('child_sub_active');
                }
            };
        };
        let disableChildSubs = function disableChildSubs(element) {
            let activeChildSubs = [].slice.call(element.querySelectorAll('.menu-item-has-children.active'));
            if (activeChildSubs.length) {
                activeChildSubs.forEach(child => {
                    child.classList.remove('active');
                    if (element.classList.contains('child_sub_active')) {
                        element.classList.remove('child_sub_active');
                    }
                });
            } else {
                return;
            };
        };
    }
}());

// menuToggle = function() {
//     document.body.classList.toggle("main-menu-active");
// };
