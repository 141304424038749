require('intersection-observer'); // polyfill for older browsers

document.addEventListener("DOMContentLoaded", function() {

    // var lazyVids = [].slice.call(document.querySelectorAll(".lazy"));
    let lazyVids = document.querySelectorAll(".lazy-vid");

    if ('IntersectionObserver' in window) {

        const observerOptions = {
            root: null, // viewport
            rootMargin: "100px", // pixeloffset outside frame
            // threshold: 0.1 // when 50% of element visible
		};
		
		let isInteracting = false;
        let interactingCount = 0;

        let lazyVidObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {

				let lazyVidWrap = entry.target;
				
				if (entry.isIntersecting) {

                    function buildVideo() {

						let lazyVid = entry.target.querySelector('source');
	
						if (lazyVid) {
							
							for (let source in lazyVidWrap.children) {
								let videoSource = lazyVidWrap.children[source];
								if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
									videoSource.src = videoSource.dataset.src;
								}
							}

							if (lazyVidWrap.classList.contains('has-interacted')) {
								lazyVidWrap.classList.remove('pause');
							} else {
								lazyVidWrap.classList.add('has-interacted');
								lazyVidWrap.load();
							}

							lazyVidWrap.play();
							// lazyVidObserver.unobserve(lazyVidWrap);
							
						}
                        
                    }

                    if (!isInteracting) {
						buildVideo();
                    } else {
                        setTimeout(function(){
                            buildVideo();
                        },
                        120 * interactingCount);
                    }

                    isInteracting = true; 
                    interactingCount = interactingCount + 1;

                    setTimeout(function(){
                        isInteracting = false; 
                        interactingCount = 0; 
                    }, 120); // reset every 120 milliseconds so we don't get a to big delay for later interactions 

                } else {
					if (lazyVidWrap.classList.contains('has-interacted')) {
						lazyVidWrap.pause();
						lazyVidWrap.classList.add('pause');
					}
				}

            });
        }, observerOptions);

		Array.prototype.forEach.call(lazyVids, function (lazyVid) {
			lazyVidObserver.observe(lazyVid);
		});

    } // Possibly fall back to a more compatible method here. But we are loading a Polyfill - so we do nothing

});
