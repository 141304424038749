document.addEventListener('click', function(e) {

    if (typeof e.target.tagName === "string" && e.target.tagName === "A") {
        let hash = e.target.hash;

        if (hash) {
            let el = document.querySelector(hash);
            if (el) {
                let offset = el.offsetTop;
                e.preventDefault();
                scrollToEl(window, offset);
            }
        }

    }

});

function scrollToEl(element, offset) {
    element.scroll({
      behavior: 'smooth',
      left: 0,
      top: offset - 135
    });
}

    