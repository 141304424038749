// Styles:
import './style.scss';

{
  class Lightbox {
    constructor() {
      this.lightbox = document.getElementById('lightbox');
      this.lightboxTriggerButtons = [].slice.call(document.querySelectorAll('.open_lightbox'));
      this.initTriggerListener = this.lightboxTriggerListener();
      this.activeVideo = null;
      this.activeVideoUrl = null;
      this.videoWrap = document.getElementById('lightbox--video');
      this.textWrap = document.getElementById('lightbox--text');
      this.lightboxCloseBtn = document.getElementById('lightbox--close');
      this.lightboxSource = document.getElementById('lightbox--source');
      this.initialized = this.onInit();
    }

    onInit() {
      console.log('Lightbox initialized');
      this.lightboxTriggerListener();
      this.lightboxCloseTrigger();
      return true;
    }

    lightboxTriggerListener() {
      const _this = this;
      console.log(this.lightboxTriggerButtons, 'lightboxTriggerButtons');
      if (this.lightboxTriggerButtons) {
        this.lightboxTriggerButtons.forEach((btn) => {
          btn.addEventListener('click', function (event) {
            console.log(event);
            if (event.target.matches('.open_lightbox')) {
              event.preventDefault();
            }

            console.log('lightboxTriggerListener', btn.dataset.lightboxType);
            const type = btn.dataset.lightboxType;
            const maxWidth = btn.dataset.lightboxMaxSize;

            if (maxWidth && window.innerWidth < maxWidth) {
            } else {
              return;
            }

            if (type) {
              if (type === 'text') {
                // let text = 'testing text';
                let text = btn.querySelector('.lightbox__text');
                if (text) {
                  _this.showTextLightbox(text.innerHTML);
                }
              }
              if (type === 'video') {
                const videoUrl = btn.dataset.videouri;
                if (videoUrl) {
                  _this.showVideoLightbox(videoUrl);
                }
              }
            }
          });
        });
      }
      return true;
    }

    showVideoLightbox(videoUrl) {
      this.lightbox.classList.add('active');
      // onAddVideoUrl(videoWrap, url);
    }

    showTextLightbox(text) {
      this.lightbox.classList.add('active');
      this.textWrap.innerHTML = text;
      // onAddVideoUrl(videoWrap, url);
    }

    lightboxCloseTrigger() {
      const _this = this;
      this.lightboxCloseBtn.addEventListener('click', function (e) {
        // onVideoPause(lightboxSource);
        _this.closeLightbox();
      });
    }

    // const onAddVideoUrl = (source, url) => {
    // 	let video = document.createElement('video');
    // 	let videoSource = document.createElement('source');
    // 	video.controls = 'controls';
    // 	videoSource.src = url;
    // 	videoSource.type = 'video/mp4';

    // 	if (activeVideoUrl === url) {
    // 		onVideoPlay();
    // 	} else {
    // 		const childNodes = source.childNodes;
    // 		if (childNodes.length) {
    // 			source.innerHTML = '';
    // 		}

    // 		video.appendChild(videoSource);
    // 		source.appendChild(video);

    // 		activeVideo = video;
    // 		activeVideoUrl = url;

    // 		onVideoPlay();
    // 	}

    // }

    // const onVideoPlay = () => {
    // 	activeVideo.play();
    // }

    // const onVideoPause = () => {
    // 	activeVideo.pause();
    // }

    closeLightbox() {
      this.lightbox.classList.remove('active');
    }
  }

  // create current Lightbox data
  let lightboxData = new Lightbox();
}
