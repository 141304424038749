// Styles:
import './style.scss';

const faqItems = [].slice.call(document.querySelectorAll('.faq-a-item'));

if (faqItems) {
	faqItems.forEach(faq =>  {
		const title = faq.querySelector('.faq-a-item-title');
		faq.addEventListener('click', (e) => {
			if (e.target === title) {
				faq.classList.toggle('faq-a-item-active');
			}			
		});
	});
}