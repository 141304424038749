// Styles:
import './style.scss';

// pin_map-img-pin

// const mapPins = [].slice.call(document.querySelectorAll('.pin_map-img-pin'));

// if (mapPins) {
// 	mapPins.forEach(faq =>  {
// 		faq.addEventListener('click', (e) => {
// 			faq.classList.toggle('active');
// 		});
// 	});
// }