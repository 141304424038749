// Styles:
import './style.scss';

const scrollTop = [].slice.call(document.querySelectorAll('.scroll_to_top'));

if (scrollTop) {

    scrollTop.forEach( function (element) {
        element.addEventListener('click', function (e) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
		});
    });
}
