export function fullScreenVideo() {
    let videoParents;
    
    videoParents = document.querySelectorAll('.intro__video');

    if(videoParents) {

        for(let i = 0; i < videoParents.length; i++) {
            
            let parentSizeData, videoElement, elWidth, elHeight, elRatio, newWidth, newHeight;

            parentSizeData = videoParents[i].getBoundingClientRect();
            videoElement = videoParents[i].getElementsByTagName('iframe');
            
            if(videoElement.length) {
                elWidth = videoElement[0].width;
                elHeight = videoElement[0].height;
    
                elRatio = elWidth / elHeight;
                
                newWidth = parentSizeData.width;
                newHeight = newWidth / elRatio;       
    
                if( newWidth >= parentSizeData.width && newHeight >= parentSizeData.height) {
    
                } else {
                    newHeight = parentSizeData.height;
                    newWidth = newHeight * elRatio;
                }
                
                videoElement[0].style.width = newWidth + 'px ';
                videoElement[0].style.height = newHeight + 'px';
            } 

        }

    }
                 
}

export function isOnScreen (el) {
    if (el) {
        const rect = el.getBoundingClientRect();
        // at least setPixels
        var pixels = 0;
        
        // at least setPixels
        return rect.bottom > 0 
            && rect.right > 0 
            && rect.left < (window.innerWidth || document.documentElement.clientWidth) 
            && rect.top < ( (window.innerHeight - pixels) || document.documentElement.clientHeight);

        // whole element
        // return (
        //     rect.top >= 0 &&
        //     rect.left >= 0 &&
        //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        //     rect.right <= (window.innerWidth || document.documentElement.clientWidth) 
        // );
    }

}

export function animateWhenInView(element) {

    for (var i = 0; i < element.length; i++) {

        var current_element = element[i];

        if( isOnScreen(current_element) && !current_element.classList.contains('is_in_view') ) {

            current_element.classList.add('is_in_view');
            
        }

    }

}


export function scrollToElement(element, offset, style) {
    
    if(element.scroll) {
        element.scroll({
            behavior: style,
            left: 0,
            top: offset
        });
    }
    
}