// Styles:
import './style.scss';

class pixImgSlider {

    constructor(slider) {
		this.slider = slider;
		this.list = this.slider.querySelector('.img_slider_a-items');
		this.slides = [].slice.call(this.list.querySelectorAll('.img_slider_a--item'));
		this.controls = this.slider.querySelector('.slider-controls');
		this.bulletControls = this.slider.querySelector('.slider-bullets');
		this.bullets = [].slice.call(this.slider.querySelectorAll('.slider-bullets > li'));
		this.prev = this.slider.querySelector('.previous');
		this.next = this.slider.querySelector('.next');
		this.startObserving = this.initIntersectionObserver();

      	this.init = this.initSlider();
	}

	initSlider() {
		this.listenForControls();
		this.listenForBulletControls();
		this.updateActiveBullets();
		this.updatePrevNext();
		this.listenToScrollEvents();
		return true;
	}

	scrollIt(slideToShow) {
		let scrollPos = slideToShow.offsetLeft;
		if(this.list.scroll) {
			this.list.scroll({
				left: scrollPos,
				behavior: 'smooth'
			});
		}

		return scrollPos;
	}

	listenToScrollEvents() {
		
		let debounced;
		const _this = this;
		this.list.addEventListener('scroll', function () {
			window.clearTimeout(debounced);
			debounced = setTimeout( _this.updateControls(), 200);
		});

	}

	updateControls() {
		this.updateActiveBullets();
		this.updatePrevNext();
	}

	updateActiveBullets() {
		
		if (this.slides && this.bullets) {

			this.slides.forEach( (slide, i) => {
				if ( slide.classList.contains('visible') ) {
					this.bullets[i].classList.add('active');
				} else {
					this.bullets[i].classList.remove('active');
				}
			} );

		}
	}

	updatePrevNext() {

		if (this.slides && this.prev && this.next) {

			const totalSlides = this.slides.length;
			this.slides.forEach( (slide, i) => {

				if ( i === 0 ) {
					if (slide.classList.contains('visible')) {
						this.prev.disabled=true;
					} else {
						this.prev.disabled=false;
					}
				}

				if ( i === (totalSlides - 1) ) {
					if ( slide.classList.contains('visible') ) {
						this.next.disabled=true;
					} else {
						this.next.disabled=false;
					}
				}
				
			});

		}
	}

	showSlide(dir) {

		let visible = [].slice.call(this.slider.querySelectorAll('.img_slider_a .visible'));
		let slideCount = this.slides.length;
		let visibleCount = visible.length;

		if (dir === this.prev) {
			if (visibleCount > 1) {
				if (visible[0].classList.contains('part') ) {
					this.scrollIt(visible[0]);
				} else if (visible[0].previousElementSibling) {
					this.scrollIt(visible[0].previousElementSibling);
				}
			} else {
				if (this.slides.indexOf(visible[0]) !== 0) {
					this.scrollIt(visible[0].previousElementSibling);
				}
			}
		}

		if (dir === this.next) {
			const lastIndex = visibleCount - 1;
			if (visibleCount > 1) {
				if (visible[lastIndex].classList.contains('part') ) {
					this.scrollIt(visible[lastIndex]);
				} else if (visible[lastIndex].nextElementSibling) {
					this.scrollIt(visible[lastIndex].nextElementSibling);
				}
			} else {
				if (this.slides.indexOf(visible[lastIndex]) !== (slideCount - 1)) {
					this.scrollIt(visible[lastIndex].nextElementSibling);
				}
			}
		}

	}

	goToIndex(index) {
		this.scrollIt(this.slides[index]);
	}

	listenForControls() {
		const _this = this;
		this.controls.addEventListener('click', function (e) {
			_this.showSlide(e.target.closest('button'));
		});
	}

	listenForBulletControls() {
		const _this = this;
		this.bulletControls.addEventListener('click', function (e) {
			if (e.target.matches('li')) {
				const target = e.target.dataset.bulletI;
				if (target) {
					_this.goToIndex(target);
				}
			}
		});
	}

	initIntersectionObserver() {

		const _this = this;

		const observerSettings = {
			root: this.slider,
			threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
			// threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
			// threshold: [0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
		}

		if ('IntersectionObserver' in window) {
			
			const callback = (slidesArray, observer) => {

				slidesArray.forEach( function (entry) {

					entry.target.classList.remove('visible');
					entry.target.classList.remove('part');
					if (!entry.intersectionRatio > 0) {
						return;
					} 
					
					if ( entry.intersectionRatio > 0.1) {
						entry.target.classList.add('visible');
						entry.target.classList.add('part');
					}

					if ( entry.intersectionRatio > 0.9) {
						entry.target.classList.remove('part');
					}

					if (entry.intersectionRatio === 1) {
						entry.target.classList.remove('part');
						_this.updateControls();
						return;
					}
				});
			}

			const observer = new IntersectionObserver(callback, observerSettings);
			this.slides.forEach( t => observer.observe(t) );
		}
	}

}



const scrollableActionsA = [].slice.call(document.querySelectorAll('.img_slider_a'));
if (scrollableActionsA) {
	scrollableActionsA.forEach(function(slider) {
		new pixImgSlider(slider);
	})
}


// function disable() {
// 	prev.disabled = slider.scrollLeft < 1;
// 	next.disabled = slider.scrollLeft === list.scrollWidth - list.offsetWidth;
// }

// var debounced;
// slider.addEventListener('scroll', function () {
// 	window.clearTimeout(debounced);
// 	debounced = setTimeout(disable, 200);
// });
